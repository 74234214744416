import { App } from '@capacitor/app';
import { AppEvents } from '@app/enums/app-events.enum';
import { BehaviorSubject, Observable } from 'rxjs';
import { Injectable, NgZone, inject } from '@angular/core';
@Injectable({
  providedIn: 'root'
})
export class AppStateService {
  private readonly appStateActive$ = new BehaviorSubject<boolean>(true);
  private readonly ngZone: NgZone = inject(NgZone);

  public get appStateActive(): Observable<boolean> {
    return this.appStateActive$.asObservable();
  }

  /*
   * Used to initialize listeners for app state changes (e.g. going or returning to/from background)
   */
  public initialize(): void {
    App.removeAllListeners();
    App.addListener(AppEvents.appStateChange, ({ isActive }) => {
      this.ngZone.run(() => {
        this.appStateActive$.next(isActive);
      });
    });
  }
}
