import { BluetoothService } from '@services/bluetooth/bluetooth.service';
import { DeviceNotification } from '@models/device';
import { DevicesService } from '@services/devices/devices.service';
import { Injectable, inject } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, map, takeUntil } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class DeviceNotificationsService {
  protected bluetoothService: BluetoothService = inject(BluetoothService);
  protected devicesService: DevicesService = inject(DevicesService);
  protected isInitialized = false;
  protected readonly notificationUnsubscribe$: Subject<void> = new Subject();
  private readonly notifications$ = new Subject<DeviceNotification | null>();

  public get notifications(): Observable<DeviceNotification | null> {
    return this.notifications$.asObservable();
  }

  public setNotifications(notification: DeviceNotification): void {
    this.notifications$.next(notification);
  }

  /*
   * Add a listener for different types of sources to handle notifications.
   * There are different notifications which can be triggered from the device like disconnect, alarm etc.
   * When a notification is received, data will be written to the device to stop the buzzer notification.
   * A local notification will be scheduled to inform the user.
   */
  public initialize(): void {
    this.bluetoothService.notifications$
      .pipe(
        filter((notification: DeviceNotification | null): notification is DeviceNotification => notification !== null),
        map(notification => notification as DeviceNotification),
        distinctUntilChanged(),
        debounceTime(1000),
        takeUntil(this.notificationUnsubscribe$)
      )
      .subscribe((notification: DeviceNotification) => this.notifications$.next(notification));
  }
}
