import { AppStateService } from '../app-state/app-state.service';
import { BehaviorSubject, Subscription, filter, map } from 'rxjs';
import { Capacitor } from '@capacitor/core';
import { Contact } from '@app/models/contact';
import { Device, DeviceNotification } from '@app/models/device';
import { DeviceNotificationsService } from '@services/device-notifications/device-notifications.service';
import { DevicePermissions } from '@app/interfaces/device-permissions.interface';
import { DeviceStatus } from '@app/enums/device-status.enum';
import { DevicesService } from '@services/devices/devices.service';
import { ForegroundService } from '@services/foreground/foreground.service';
import { GuardMode } from '@app/interfaces/guard-mode.interface';
import { Injectable, NgZone, inject } from '@angular/core';
import { LocationService } from '@services/location/location.service';
import { NotificationType } from '@app/enums/notification-type.enum';
import { Session, SessionStatus } from '@app/models/session';
import { SessionsService } from '@services/sessions/sessions.service';
import { User } from '@app/models/user';
import BackgroundGeolocation, {
  Subscription as BackgroundSubscription
} from '@transistorsoft/capacitor-background-geolocation';

@Injectable({
  providedIn: 'root'
})
export class GuardModeService {
  public readonly isActive = new BehaviorSubject<GuardMode>({
    isActive: false
  });
  private readonly appSubscriptions: Subscription[] = [];
  private readonly appStateService: AppStateService = inject(AppStateService);
  private readonly backgroundSubscriptions: BackgroundSubscription[] = [];
  private readonly devicesService: DevicesService = inject(DevicesService);
  private readonly foregroundService: ForegroundService = inject(ForegroundService);
  private readonly locationService: LocationService = inject(LocationService);
  private readonly deviceNotificationsService: DeviceNotificationsService = inject(DeviceNotificationsService);
  private readonly sessionsService: SessionsService = inject(SessionsService);
  private readonly ngZone: NgZone = inject(NgZone);

  /*
   * Used to check if the user can activate guard mode. Depends on user credits, device status, contacts, and permissions
   */
  public canActivateGuardMode(
    user: User,
    device: Device,
    contacts: Contact[],
    permissions: DevicePermissions
  ): boolean {
    let canActivate = true;
    if (!user || !user.credits) {
      canActivate = false;
    }
    if (!device || !device.status || device.status !== DeviceStatus.CONNECTED) {
      canActivate = false;
    }
    if (!contacts || contacts.length === 0) {
      canActivate = false;
    }
    if (permissions && !permissions.ble) {
      canActivate = false;
    }
    if (permissions && !permissions.location) {
      canActivate = false;
    }
    return canActivate;
  }

  /*
   * Used to enable guard mode and start background services as well as subscribe to device services (e.g. alarm)
   */
  public async enableGuardMode(session?: Session): Promise<Session> {
    try {
      if (this.isActive.getValue() && this.isActive.getValue().isActive) {
        throw new Error('Guard mode is already active');
      }

      if (!session) {
        session = await this.sessionsService.startSession();
      }
      this.isActive.next({ isActive: true, session });
      await this.startBackgroundServices(session.id);
      return session;
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  /*
   * Callback in case user stops the session manually
   */
  public async onStoppedByUser(): Promise<void> {
    const guardMode = this.isActive.getValue();
    // check if guard mode is active and we have a session
    if (guardMode && guardMode.isActive && guardMode.session) {
      await this.stopSession(SessionStatus.StoppedByUser);
      await this.disableGuardMode();
    }
  }

  /*
   * Callback in case of emergency, native layer already has stopped the session via http request
   */
  private async onEmergency(): Promise<void> {
    const guardMode = this.isActive.getValue();
    if (guardMode && guardMode.isActive && guardMode.session) {
      await this.disableGuardMode();
    }
  }

  /*
   * Callback in case of silent alarm
   */
  private async onSilentAlarm(): Promise<void> {
    const guardMode = this.isActive.getValue();
    // check if guard mode is active and we have a session
    if (guardMode && guardMode.isActive && guardMode.session) {
      await this.stopSession(SessionStatus.Emergency);
      await this.disableGuardMode();
    }
  }

  /*
   * Used to stop the session and disable guard mode
   */
  private async stopSession(status: SessionStatus): Promise<void> {
    const timestamp = new Date().toISOString();
    await this.sessionsService.stopSession({
      endTimestamp: timestamp,
      modifiedTimestamp: timestamp,
      status
    });
  }

  /*
   * Used to disable guard mode and unsubscribe from device services (e.g. alarm) as well as stop background geolocation
   */
  private async disableGuardMode(): Promise<void> {
    try {
      await this.stopBackgroundServices();
      this.isActive.next({ isActive: false });
    } catch (error) {
      console.error(error);
    }
  }

  /*
   * Used to start background services
   */
  private async startBackgroundServices(sessionId: string): Promise<void> {
    await this.locationService.updateConfig(sessionId);
    await this.devicesService.updateBleConfig(sessionId);
    await this.foregroundService.enable();
    await this.locationService.startBackgroundGeolocation();
    await this.devicesService.subscribeToServices();
    this.addSubscription();
  }

  /*
   * Used to stop background services
   */
  private async stopBackgroundServices(): Promise<void> {
    await this.devicesService.unsubscribeFromServices();
    await this.locationService.stopBackgroundGeolocation();
    await this.foregroundService.disable();
    await this.removeSubscription();
  }

  /*
   * Used to add subscriptions to appStateService and deviceNotificationsService
   */
  private async addSubscription(): Promise<void> {
    this.appSubscriptions.push(
      this.appStateService.appStateActive.subscribe(async (isInForeGround: boolean) => {
        // if app moves to the background then show a notification on iOS, on Android this is done automatically by geolocation background plugin
        if (
          !isInForeGround &&
          this.isActive.getValue() &&
          this.isActive.getValue().isActive &&
          Capacitor.getPlatform() === 'ios'
        ) {
          this.deviceNotificationsService.setNotifications({
            type: NotificationType.DEVICE_BACKGROUND,
            value: null
          });
        }
      })
    );

    this.appSubscriptions.push(
      this.deviceNotificationsService.notifications
        .pipe(
          filter(
            (notification: DeviceNotification | null): notification is DeviceNotification => notification !== null
          ),
          map(notification => notification as DeviceNotification),
          filter((notification: DeviceNotification) => notification.type === NotificationType.DEVICE_EMERGENCY)
        )
        .subscribe(async () => await this.onEmergency()),
      this.deviceNotificationsService.notifications
        .pipe(
          filter(
            (notification: DeviceNotification | null): notification is DeviceNotification => notification !== null
          ),
          map(notification => notification as DeviceNotification),
          filter((notification: DeviceNotification) => notification.type === NotificationType.SILENT_EMERGENCY)
        )
        .subscribe(async () => await this.onSilentAlarm())
    );

    this.backgroundSubscriptions.push(
      BackgroundGeolocation.onHeartbeat(async () => {
        // used to update timePassed on progress page
        this.ngZone.run(() => {
          const session = this.sessionsService.entity.getValue();
          if (session) {
            this.sessionsService.entity.next({ ...session, modifiedTimestamp: new Date().toISOString() });
          }
        });
      })
    );
  }

  /*
   * Used to remove subscriptions from appStateService and deviceNotificationsService
   */
  private async removeSubscription(): Promise<void> {
    this.appSubscriptions.forEach(subscription => subscription.unsubscribe());
    this.backgroundSubscriptions.forEach(subscription => subscription.remove());
  }
}
