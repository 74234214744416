import { AppConstants } from '@app/app.constants';
import { ForegroundService as CapForegroundService } from '@capawesome-team/capacitor-android-foreground-service';
import { Capacitor } from '@capacitor/core';
import { Injectable, inject } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class ForegroundService {
  private readonly translate: TranslateService = inject(TranslateService);

  public async enable(): Promise<void> {
    if (Capacitor.getPlatform() !== 'android') {
      return;
    }

    await CapForegroundService.startForegroundService({
      // random integer that will be used to identify the service
      id: AppConstants.FOREGROUND_SERVICE_ID,
      smallIcon: 'notification_alarm_icon',
      title: this.translate.instant('labels_notifications_device_background_title'),
      body: this.translate.instant('labels_notifications_device_background_description')
    });
  }

  public async disable(): Promise<void> {
    if (Capacitor.getPlatform() !== 'android') {
      return;
    }
    await CapForegroundService.stopForegroundService();
  }
}
